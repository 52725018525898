<template>
  <div class="contatain">
    <div class="tab">
      <!-- tab栏 -->
      <div
        class="tab-item"
        v-for="(item, index) in tabList"
        :key="`a${index}`"
        :class="curTab === item.id ? 'tab-item-active' : ''"
        @click="curTab = item.id"
      >
        {{ item.name }}
      </div>
      <div class="total-user flex-cc">
        <div class="m-r-20">总授权用户数：</div>
        <div>{{ totalUser }}人</div>
      </div>
    </div>
    <filter-item @dealFilter="dealFilter" />
    <div class="content-wrap">
      <el-card class="row2-card" v-loading="loading">
        <div class="flex-row flex-as">
          <div class="title">数据表</div>
          <div class="table-remark flex-row">
            <span>注：</span>
            <div>
              1.因微信数据延迟，昨天的部分数据可能未更新；<br />
              2.“访问人次”、“访问用户数”暂未能按剔除名单计算。
            </div>
          </div>
        </div>
        <div class="flex-row flex-as">
          <div class="computed-title">用户人流</div>
          <el-table
            border
            :data="userFlowDataVO"
            :header-cell-style="thStyle"
            :cell-style="cellStyle"
            style="width: auto; flex: 0 1 auto"
          >
            <el-table-column width="80"> 总数 </el-table-column>
            <el-table-column
              v-for="(item, index) in userFlowDataVOItems"
              :key="index"
              :label="item.label"
              :render-header="(h, c) => renderHeader(h, c, item.title)"
              :width="item.width"
            >
              <template slot-scope="scope">
                {{ `${scope.row[item.key]}${item.unit}` }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="flex-row flex-as">
          <div class="computed-title">游戏记录</div>
          <el-table
            border
            :data="gameRecordDataVO"
            :cell-style="cellStyle"
            :header-cell-style="thStyle"
            style="width: auto; flex: 0 1 auto"
          >
            <el-table-column width="80"> 总数 </el-table-column>
            <el-table-column
              v-for="(item, index) in gameRecordDataVOItems"
              :key="index"
              :label="item.label"
              :render-header="(h, c) => renderHeader(h, c, item.title)"
              :width="item.width"
            >
              <template slot-scope="scope">
                {{
                  `${
                    index === 4
                      ? Math.ceil(scope.row.gameVoiceTimeConsumer / 60)
                      : scope.row[item.key]
                  }${item.unit}`
                }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="flex-row flex-as">
          <div class="computed-title">交易支付</div>
          <el-table
            border
            :data="transactionPayDataVO"
            :header-cell-style="thStyle"
            :cell-style="cellStyle"
            style="width: auto; flex: 0 1 auto"
          >
            <el-table-column width="80"> 总数 </el-table-column>
            <el-table-column
              v-for="(item, index) in transactionPayDataVOItems"
              :key="index"
              :label="item.label"
              :render-header="(h, c) => renderHeader(h, c, item.title)"
              :width="item.width"
            >
              <template slot-scope="scope">
                {{ `${scope.row[item.key]}${item.unit}` }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="flex-row flex-as">
          <div class="title">数据图</div>
          <div class="flex-column">
            <el-checkbox-group v-model="checkList" style="width: 760px">
              <el-checkbox
                v-for="(item, index) in nameList.slice(0, 4)"
                :key="index"
                style="width: 120px"
                :label="index + 1 + ''"
                :disabled="isPieType"
                >{{ item }}</el-checkbox
              ><br />
              <el-checkbox
                v-for="(item, index) in nameList.slice(4, 9)"
                :key="index + 4"
                style="width: 120px"
                :label="index + 5 + ''"
                :disabled="isPieType"
                >{{ item }}</el-checkbox
              ><br />
              <el-checkbox
                v-for="(item, index) in nameList.slice(9, 15)"
                :key="index + 9"
                style="width: 120px"
                :label="index + 10 + ''"
                :disabled="isPieType"
                >{{ item }}</el-checkbox
              >
            </el-checkbox-group>
            <el-button
              class="m-t-10"
              type="primary"
              size="mini"
              @click="showChart"
              :disabled="isPieType"
              >生成/刷新数据图</el-button
            >
            <echart-item
              v-loading="loading"
              :data="mainDataGraphTimeVOList"
              dataType="main"
              :lineList="lineList"
              :nameList="nameList"
              :pieData1="pieData1"
              :pieData2="pieData2"
              pieDataName1="人次（单位：次）"
              pieDataName2="用户数（单位：人）"
              @pieType="pieType"
            />
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import FilterItem from "./common/component/FilterItem.vue";
import EchartItem from "./common/component/EchartItem.vue";
import { timestampToDate, debounce } from "@tools";
export default {
  components: {
    FilterItem,
    EchartItem,
  },
  data() {
    return {
      tabList: [{ name: "整体总览", id: 1 }],
      totalUser: 0, // 总授权用户数
      checkList: ["1"], // 选项选择的数组
      pieCheckList: ["1", "2", "3", "4", "5", "8", "9"], // 饼状图需要勾选死的选项
      params: {
        // 默认参数
        // 请求参数
        ignoreFlag: true,
        platform: "",
        endTime: timestampToDate(new Date().getTime(), false),
        startTime: timestampToDate(
          new Date().getTime() - 3600 * 1000 * 24 * 30,
          false
        ),
      },
      curTab: 1,
      loading: false,
      gameRecordDataVO: [], // 游戏表格数据
      transactionPayDataVO: [], // 交易表格数据
      userFlowDataVO: [], // 用户表格数据
      mainDataGraphTimeVOList: {}, // 图表数据
      lineList: ["访问人次", "总游戏人次", "付费人次"], // 混合图时需要折线展示的选项
      nameList: [
        // 所有选项的名字数组
        "访问人次",
        "访问用户数",
        "累计访问用户数",
        "新增用户数",
        "总游戏人次",
        "总游戏用户数",
        "退出用户数⑤",
        "游戏局数",
        "语音耗时",
        "付费人次",
        "付费用户数",
        "现金收入",
        "总销售额",
        "用户花费点券量",
      ],
      pieData1: [], // 左饼状图
      pieData2: [], // 右饼状图
      isPieType: false, // 是否选中饼状图
      userFlowDataVOItems: [
        {
          label: "访问人次",
          title: "访问小程序的人次，同一用户重复计算。",
          key: "accessQuantity",
          unit: "次",
          width: "130",
        },
        {
          label: "访问用户数",
          title: "访问小程序的用户数，同一用户当天多次访问不重复计。",
          key: "accessUserQuantity",
          unit: "人",
          width: "130",
        },
        // {
        //   label: "累计访问用户数",
        //   title: "历史累计访问小程序的用户数，同一用户多次访问不重复计。",
        //   key: "accessTotalUserQuantity",
        //   unit: "人",
        //   width: "150",
        // },
        {
          label: "新增用户数",
          title: "新增授权登录小程序的用户数，同一用户只计算一次。",
          key: "authorizeUserQuantity",
          unit: "人",
          width: "130",
        },
      ],
      gameRecordDataVOItems: [
        {
          label: "总游戏人次",
          title: "开局过游戏的人次，同一用户重复计算。",
          key: "gameQuantity",
          unit: "次",
          width: "130",
        },
        {
          label: "总游戏用户数",
          title:
            "玩过游戏的用户数之和，同一用户当天多次访问不重复计。（例如一个用户玩过外购本和免费本内购的游戏各一次，不重复计，仅算一个总游戏用户数。）",
          key: "gameUserQuantity",
          unit: "人",
          width: "130",
        },
        {
          label: "退出用户数⑤",
          title:
            "当剧本有剧本内容收费项时，到达“剧终投票”后，且购买了剧本内容，退出的用户，同一用户当天多次访问不重复计。",
          key: "gameQuit5UserQuantity",
          unit: "人",
          width: "130",
        },
        {
          label: "游戏局数",
          key: "gameRoundQuantity",
          unit: "局",
          width: "130",
        },
        {
          label: "语音耗时",
          key: "gameVoiceTimeConsumer",
          unit: "分钟",
          width: "130",
        },
      ],
      transactionPayDataVOItems: [
        {
          label: "付费人次",
          key: "paidQuantity",
          unit: "次",
          width: "130",
        },
        {
          label: "付费用户数",
          key: "paidUserQuantity",
          unit: "人",
          width: "130",
        },
        {
          label: "现金收入",
          key: "cashIncome",
          unit: "元",
          width: "130",
        },
        {
          label: "总销售额",
          key: "totalSales",
          title:
            "全部销售额，外购销售额、总内购销售额之和。",
          unit: "点券",
          width: "130",
        },
        {
          label: "用户花费点券量",
          key: "userBuyCouponQuantity",
          unit: "点券",
          width: "130",
        },
      ],
    };
  },
  async created() {
    this.getMainDataList(this.params);
  },
 
  methods: {
    getMainDataList: debounce(async function (params) {
      this.loading = true;
      params.ignoreMinute = localStorage.ignoreMinute
      const data = await this.$api.chiguaData
        .getMainDataList(params)
        .finally(() => {
          this.loading = false;
        });
      // 数据赋值
      let {
        gameRecordDataVO,
        transactionPayDataVO,
        userFlowDataVO,
        totalUser,
      } = data;
      this.gameRecordDataVO.splice(0, 1, gameRecordDataVO);
      this.transactionPayDataVO.splice(0, 1, transactionPayDataVO);
      this.userFlowDataVO.splice(0, 1, userFlowDataVO);
      this.totalUser = totalUser;
      this.getPieData(gameRecordDataVO, transactionPayDataVO, userFlowDataVO);
      //   获取图的数据
      this.getMainDataChart(params);
    }, 300),
    async getMainDataChart(params) {
      this.loading = true;
      // 设置图选项，三元防止饼图状态时，筛选，然后影响到后面切其他图
      params.dataFlagList = this[
        `${this.isPieType ? "pieCheckList" : "checkList"}`
      ]
        .sort((a, b) => a - b)
        .toString();
     params.ignoreMinute = localStorage.ignoreMinute
      const data = await this.$api.chiguaData
        .getMainDataChart(params)
        .finally(() => {
          this.loading = false;
        });
      this.mainDataGraphTimeVOList = data.mainDataGraphTimeVOS;
    },
    getPieData(gameRecordDataVO, transactionPayDataVO, userFlowDataVO) {
      // 配置饼图数据，可写死
      this.pieData1.splice(
        0,
        3,
        { name: "访问人次", value: userFlowDataVO.accessQuantity },
        { name: "总游戏人次", value: userFlowDataVO.accessQuantity },
        { name: "付费人次", value: transactionPayDataVO.paidQuantity }
      );
      this.pieData2.splice(
        0,
        4,
        {
          name: "访问用户数",
          value: userFlowDataVO.accessUserQuantity,
        },
        {
          name: "新增用户数",
          value: userFlowDataVO.authorizeUserQuantity,
        },
        {
          name: "总游戏用户数",
          value: gameRecordDataVO.gameUserQuantity,
        },
        {
          name: "付费用户数",
          value: transactionPayDataVO.paidUserQuantity,
        }
      );
    },
    openDialog(ref) {
      this.$refs[ref].showDialog = true;
    },
    dealFilter(e) {
      // 顶部筛选条件变化回调
      this.params = e;
      this.getMainDataList(e);
    },
    showChart() {
      // 按钮“展示数据”事件
      this.params.dataFlagList = this.checkList.sort().toString();
      this.getMainDataChart(this.params);
    },
    pieType(isPie) {
      // 切换到饼状图时回调，变灰
      if ((!this.isPieType && isPie) || (this.isPieType && !isPie)) {
        [this.checkList, this.pieCheckList] = [
          this.pieCheckList,
          this.checkList,
        ];
      }
      this.isPieType = isPie;
    },
    thStyle() {
      return {
        background: "rgba(197, 192, 255, 0.1)",
        color: "#000",
        textAlign: "center",
      };
    },
    cellStyle() {
      return {
        fontWeight: "500",
        color: "#000",
        textAlign: "center",
      };
    },
    renderHeader: function (h, { column }, title) {
      const arr = column.label.split("-");
      const res = arr.length > 1 ? [arr[0], h("br"), arr[1]] : [column.label];
      if (!title) return res;
      return [
        ...res,
        h(
          "el-tooltip",
          {
            props: {
              placement: "top",
              effect: "light",
              //content:"提示框内容",//如果提示内容短不需要换行，直接使用这个提示内容
            },
          },
          [
            h(
              "div",
              {
                //当前div是为了实现换行，就这换行研究了好久。。。
                slot: "content",
              },
              [title]
            ),
            h("span", {
              //问号模块
              class: {
                "el-icon-question": true,
                "m-l-5": true,
              },
            }),
          ]
        ),
      ];
    },
  },
  watch: {
    checkList: {
      deep: true,
      handler(val) {
        if (val.length > 10) {
          this.checkList.pop();
          this.$message.warning("由于数据量巨大，只能一次性选十个哦！");
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./common/style/dataForm.scss";
@import "./common/style/dataTable.scss";
.item-date {
  text-align: left;
}
.total-user {
  width: 444px;
  height: 24px;
  background: #fae1ee;
  border-radius: 4px;
  border: 1px solid #f394ac;
}
</style>